type Status = 'initial' | 'loading' | 'loaded' | 'error';

interface CustomHTMLScriptElement extends HTMLScriptElement {
  status: Status;
}

export const createScriptIfNeeded = (url: string) => {
  let script: CustomHTMLScriptElement | null = document.querySelector(`script[src="${url}"]`);
  const status = script?.status ?? 'initial';

  return new Promise((resolve, reject) => {
    if (status === 'error') {
      return reject(new Error(`Failed to load script: ${url}`));
    }

    if (status === 'loaded') {
      return resolve(true);
    }

    const onError = () => {
      script!.status = 'error';
      reject();
    };

    const onLoaded = () => {
      script!.status = 'loaded';
      resolve(true);
    };

    if (script !== null && status === 'loading') {
      script!.addEventListener('error', onError);
      script!.addEventListener('load', onLoaded);
    }

    if (status === 'initial') {
      script = document.createElement('script') as CustomHTMLScriptElement;
      script.status = 'loading';
      script.src = url;
      script.async = true;
      script!.addEventListener('error', onError);
      script!.addEventListener('load', onLoaded);

      const parent = document.body || document.documentElement;
      parent.append(script);
    }
  });
};

export const removeScript = (url: string): void => {
  const script = document.querySelector(`script[src="${url}"]`);
  script?.remove();
};
